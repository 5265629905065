
import React from "react"

import SeminarForm from "../../components/negosyonow/forms/seminarform"

const basicFormFields = [
	//
	{"label":"Presentor", "field": "nnseminar_presenter", "value": "", "input": "text", "mode": "normal", "info":"Custom 'Presented by' name, but it removes link to organization page"},
	{"label":"Banner", "field": "nnseminar_banner", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":1200, "imageheight":480, "imageformat":"jpg", "uploadtype": "https","filehost": "cdn.ngnw.ph", "filelocation":"images/seminar/banner"},
	{"label":"Mobile Banner", "field": "nnseminar_bannermobile", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":1024, "imageheight":1024, "imageformat":"jpg", "uploadtype": "https","filehost": "cdn.ngnw.ph", "filelocation":"images/seminar/bannermobile"},
];


const detailsFormFields = [
	//
	{"label":"Summary", "field": "nnseminar_summary", "value": "", "input": "textarea", "mode": "required"},
	{"label":"Description", "field": "nnseminar_description", "value": "", "input": "richtextarea", "mode": "required"},
];


const presentationFormFields = [
	{"label":"Registration Deadline", "field": "nnseminar_deadline", "value": "", "input": "date", "mode": "required"},

	{"label":"Start Time", "field": "nnseminar_datetimestart", "value": "", "input": "datetime", "mode": "required"},
	{"label":"End Time", "field": "nnseminar_datetimeend", "value": "", "input": "datetime", "mode": "required"},
	{"label":"Type", "field": "urltype_id", "value": "", "input": "combo", "mode": "required", "options": [
		{"value":1,"display":"TBD or N/A"},
		{"value":2,"display":"Zoom"},
		{"value":3,"display":"Google Meet"},
		{"value":4,"display":"Venue"},
	]},
	{"label":"Link/URL", "field": "nnseminar_seminarlink", "value": "", "input": "text", "mode": "normal", "info":"Meeting link or map link"},
	{"label":"Venue/Notes", "field": "nnseminar_venuedetails", "value": "", "input": "textarea", "mode": "normal", "info":"Venue Address and/or notes"},
	{"label":"Registration Fee", "field": "nnseminar_regfee", "value": "", "input": "currency", "mode": "required"},
	{"label":"Payment Instructions", "field": "nnseminar_paymentinstructions", "value": "", "input": "textarea", "mode": "normal", "info": "Displayed after registration, only when there are fees"},
];


const SeminarPage = ({location}) => {

	return <SeminarForm
				location={location}
				allowadd={true}
				allowdelete={false}
				customeditformfields={basicFormFields.concat(detailsFormFields).concat(presentationFormFields)}
				customnewformfields={basicFormFields.concat(presentationFormFields)}
				editSubFormFields={[]}
				newSubformFields={[]}
			/>


}


export default SeminarPage;
